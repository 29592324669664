import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./TopPageHeader.css";

const TopPageHeader = ({ title, link }) => {
  return (
    <Container fluid className="top-header-section">
      <Row>
        <Col>
          <div className="div-overlay"></div>
          <div className="div-content">
            <h1>{title}</h1>
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={link}>{title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TopPageHeader;
